import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "components/layout";
import SEO from "components/seo";
import Row from "components/row";

import "./style.css";

const Wiki = ({ data }) => {
  const articles = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title="Wiki" />
      <div className="wrapper">
        <ul className="wiki-list">
          {articles.map(({ node }) => {
            const { slug, title: text, date } = node.frontmatter;
            const link = `/wiki/${slug}`;
            return (
              <Row
                key={slug}
                link={link}
                text={`On ${text}`}
                dateCreated={date}
              />
            );
          })}
        </ul>
      </div>
    </Layout>
  );
};

Wiki.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({}),
        })
      ),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`;

export default Wiki;
